import React, { Component } from "react"
import { graphql } from "gatsby"
import Page from "../templates/page"


export const query = graphql`
  {
    allWordpressPage(filter: {slug: {eq: "privacy-policy"}}) {
      edges {
        node {
          id
          title
          slug
          content
        }
      }
    }
  }
`

export default ({ children, data }) => {
  const pageContent = data.allWordpressPage.edges[0].node
  const title = pageContent.title
  const content = pageContent.content 
  return (
    <Page
    content={content}
    title={title}
    >
      {children}
    </Page>
  )
}

